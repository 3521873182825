import { observer } from 'mobx-react';
import React from 'react';
import { RejectReasonsModel } from './domain/Reasons';
import { RejectReason } from './Reason';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { RejectReasonModel } from './domain/Reason';

interface Props {
    RejectReasonModel: RejectReasonsModel;
    reasons: RejectReasonModel[];
    hasChanges: boolean;
    onSave: (reasons: RejectReasonModel[]) => void;
    onDelete: (reason: RejectReasonModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reasonsWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const RejectReasons: React.FC<Props> = observer((props) => {

const { RejectReasonModel, reasons, hasChanges} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return RejectReasonModel.addNewLine();
};

const handleSaveClick = () => {
  props.onSave(reasons);
};

const handleDelete = (reason) => {
  props.onDelete(reason);
};

  return (
    <div className={classes.reasonsWrapper}>
      <div className={classes.saveBtn}>
      <Button
          color="primary"
          disabled={!hasChanges}
          className={classes.label}
          startIcon= {<CloudDownloadOutlinedIcon/>} //{user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
          onClick={handleSaveClick}
      >
          Save
      </Button></div>
      { reasons.map((line, index) =>
        <RejectReason reason={line}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </div>
  );
});