import { flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';



export const setUseExistingCallAPI = async (model: any, originCallNumber: string, equipmentID: string, debtorID: string, addressID: string) => {
  const { api } = getEnv<IStoresEnv>(model);
  const fetchData = flow(function* fetch() {
    const validateCallResponse = yield api.get(
      `/api/ServiceEstimate/ValidateCall?equipmentID=${encodeURIComponent(equipmentID)}&debtorID=${encodeURIComponent(debtorID)}&addressID=${encodeURIComponent(addressID)}&callNumber=${encodeURIComponent(originCallNumber)}`);
    model.OriginCallNumber = originCallNumber;
    model.DebtorID = debtorID;
    model.Location = addressID;
    model.EquipmentID = equipmentID;
    if (validateCallResponse.status === 204) {
      model.ValidateCall = '';
      model.ValidateCallError = `Call doesn't exist`
    } else if (validateCallResponse.status === 200) {
        model.ValidateCall = 'success';
        model.ValidateCallError = validateCallResponse.data.errorText;
    } else {
      model.ValidateCallError = `Failure retrieving call details`;
    }
  });
  return await fetchData();
};
