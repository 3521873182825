import { types, Instance } from 'mobx-state-tree';
import { setDebtorIDAPI } from './CustomerDetails';
import { setEquipmentIDAPI } from './EquipmentDetails';
import { setAddressIDAPI } from './AddressDetails';
import { setUseExistingCallAPI } from './UseExistingCall';

export const EstimateRequestDetails = types
  .model({   
    ID: types.optional(types.string, ''),
    CreatedAt: types.optional(types.string, ''),
    CreatedBy: types.optional(types.string, ''),
    EstimateNr: types.optional(types.string, ''),
    Priority: types.optional(types.string, ''),
    Area: types.optional(types.string, ''),
    OriginCallNumber: types.optional(types.string, ''),
    UseExistingCall: types.optional(types.maybeNull(types.boolean), false),
    ValidateCallError: types.optional(types.maybeNull(types.string), null),
    DebtorID: types.optional(types.string, ''),
    DebtorIDError: types.optional(types.string, ''),
    CustomerName: types.optional(types.string, ''),
    ContactName: types.optional(types.string, ''),
    ContactPhone: types.optional(types.string, ''),
    ContactEmail: types.optional(types.string, ''),
    Location: types.optional(types.string, ''),
    AddressIDs: types.optional(types.array(types.string), []),
    Address: types.optional(types.string, ''),
    Suburb: types.optional(types.string, ''),
    Postcode: types.optional(types.string, ''),
    State: types.optional(types.string, ''),
    EquipmentID: types.optional(types.string, ''),
    EquipmentIDError: types.optional(types.string, ''),
    Make: types.optional(types.string, ''),
    Model: types.optional(types.string, ''),
    SerialNumber: types.optional(types.string, ''),
    FleetNumber: types.optional(types.string, ''),
    EngineHours: types.optional(types.string, ''),
    JobDescription: types.optional(types.string, ''),
    EstimatedLabourHours: types.optional(types.string, ''),
    Status: types.optional(types.string, ''),
    Owner: types.optional(types.string, ''),
    Branch: types.optional(types.string, ''),
    Details: types.optional(types.string, ''),
    Actions: types.optional(types.string, ''),
    ViewAll: types.optional(types.boolean, false),
    FleetType: types.optional(types.string, ''),
    SubmittedDate: types.optional(types.string, ''),
    AdminFee: types.optional(types.number, 0),
    PriceLevel: types.optional(types.string, ''),
    LabourRate: types.optional(types.number, 0),
    LabourCost: types.optional(types.number, 0),
  })
  .actions((self) => {
    return {
      setDebtorID: (debtorID: string) => {
        self.DebtorID = debtorID;
      },
      validateDebtorID: (debtorID: string) => {
        setDebtorIDAPI(self, debtorID);
      },
      setOriginCallNumber: (originCallNumber: string) => {
        self.OriginCallNumber = originCallNumber;
      },
      setAddressID: (debtorID: string, addressID: string)  => {
        setAddressIDAPI(self, debtorID, addressID);
      },
      setEquipmentID: (debtorID: string, addressID: string, equipmentID: string)  => {
        self.EquipmentID = equipmentID;
      },
      validateEquipmentID: (debtorID: string, addressID: string, equipmentID: string)  => {
        setEquipmentIDAPI(self, debtorID, addressID, equipmentID);
        if (self.FleetType === 'Rental') {
          self.LabourRate = 0;
      }},
      validateCall: async (OriginCallNumber: string, equipmentID: string, debtorID: string, addressID: string)  => {
        await setUseExistingCallAPI(self, OriginCallNumber, equipmentID, debtorID, addressID);
      },
      setUseExistingCall: (UseExistingCall: boolean) => {
        self.UseExistingCall = UseExistingCall;
      },
      setValidateCallError: () => {
        self.ValidateCallError = '';
      },
      setContactName: (contactName: string) => {
        self.ContactName = contactName;
      },
      setContactPhone: (contactPhone: string) => {
        self.ContactPhone = contactPhone;
      },
      setContactEmail: (contactEmail: string) => {
        self.ContactEmail = contactEmail;
      },
      validateContactEmail: (contactEmail: string) => {
        self.ContactEmail = contactEmail;
      },
      setEngineHours: (engineHours: string) => {
        self.EngineHours = engineHours;
      },
      setJobDescription: (jobDescription: string) => {
        self.JobDescription = jobDescription;
      },
      setEstimatedLabourHours: (estimatedLabourHours: string) => {
        self.EstimatedLabourHours = estimatedLabourHours;
      },
    };
  });

export type RequestDetailsModel = Instance<typeof EstimateRequestDetails>;