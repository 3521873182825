import { types, Instance } from 'mobx-state-tree';

export const RejectReason = types.model({
  id: types.optional(types.string, ''),
  reason: types.optional(types.string, ''),
  hidden: types.optional(types.boolean, false)
})
  .actions((self) => {
    return {
      setReason: (reason: string) => {
          self.reason = reason;
      },
      setHidden: (Hidden: boolean) => {
          self.hidden = Hidden;
      },

    };
  })
  .views((self) => ({
      equals(reason) {
          return self.reason === reason.reason
          && self.hidden === reason.hidden;
      }
    })
  );

export type RejectReasonModel = Instance<typeof RejectReason>;
