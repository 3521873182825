import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { RejectReasons } from '../domain/Reasons';
import { RejectReasonModel } from '../domain/Reason';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const RejectConfigStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(RejectReasons, {}),
    reasons: types.optional(RejectReasons, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapReason = (x) => ({
      id: x.id, 
      reason: x.reason,
      hidden: x.hidden
    });
    const mapSettings = (x) => ({
      reasons: x.reasons
    });

    const applyData = (data) => {
        if(data.reasons)
        {
          applySnapshot(self.originalSettings,
            { reasons: data.reasons.map((x) => mapReason(x)) });
          applySnapshot(self.reasons,
            { reasons: data.reasons.map((x) => mapReason(x)) });
        }

        for( let i=1; i<=(4 - self.reasons.reasons.length); i++)
        {
          const reason: any = {
              id: uuidv4()
              };
              self.reasons.reasons.push(reason)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimateRejectConfig`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateRejectConfig/save`,
            { payload: mapSettings(self.reasons) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (reason: RejectReasonModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimateRejectConfig/delete`,
              { payload: mapReason(reason) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (reasons) => {  
      const newRecord = reasons.filter(
        (t) => !self.originalSettings.reasons.some( (x) => t.equals(x))  && t.reason != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(reasons);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type IRejectStore = Instance<typeof RejectConfigStore>;
