import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField, Grid, FormControl, Paper, Accordion, Typography, IconButton, AccordionDetails, Tooltip, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IRequestStore } from './store/RequestStore';
import { inject, observer } from 'mobx-react';
import { STORE_KEYS } from '@core';
import { useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import SingleSelectWithKey from 'components/SingleSelectWithKey';
import SingleSelect from 'components/SingleSelect';
import { debounce } from 'lodash';
import { FileUploader } from "react-drag-drop-files";


interface PathParams {
    requestNumber: string;
}

interface Props extends RouteComponentProps<PathParams> {
    RequestStore: IRequestStore;
}

const RequestFormComponent: React.FC<Props> = observer((props) => {

    const store = props.RequestStore;

    const params = useParams<PathParams>();
    const requestNumber = params.requestNumber;

    const [isRequestOpen, setIsRequestOpen] = useState(true);
    const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(true);
    const [isEquipmentDetailsOpen, setIsEquipmentDetailsOpen] = useState(true);
    const [isJobDescriptionOpen, setIsJobDescriptionOpen] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const handleExpandCollapseRequestClick = () => {
        setIsRequestOpen(!isRequestOpen);
    };
    const handleExpandCollapseCustomerDetailsClick = () => {
        setIsCustomerDetailsOpen(!isCustomerDetailsOpen);
    };
    const handleExpandCollapseEquipmentDetailsClick = () => {
        setIsEquipmentDetailsOpen(!isEquipmentDetailsOpen);
    };
    const handleExpandCollapseJobDescriptionClick = () => {
        setIsJobDescriptionOpen(!isJobDescriptionOpen);
    };

    useEffect(() => {
        store.fetchData();
        store.selectRequestNumber(requestNumber);
    }, [requestNumber, store]);

    useEffect(() => {
        const errorMessage = props.RequestStore.uploadErrorMessage;
        if (errorMessage) {
            alert(errorMessage); // Or trigger a modal popup
            // Optionally, clear the error message after displaying it
            props.RequestStore.clearUploadError();
        }
    }, [props.RequestStore.uploadErrorMessage]);


    const validateOriginatingCallNumber = (callNumber: string) => {
        const re = /^\d{4}-\d{4}$/;
        return callNumber === '' || re.test(callNumber);
    }
    const validatePhoneNumber = (phoneNumber: string) => {
        const re = /^(?:\(04\)|04|\+614|0011614|\(02\)|02|\+612|0011612|\(03\)|03|\+613|0011613|\(07\)|07|\+617|001167|0011617|\(08\)|08|\+618|0011618|\(02\) |02 |\+612 |0011612 |\(03\) |03 |\+613 |0011613 |\(07\) |07 |\+617 |0011617 |\(08\) |08 |\+618 |0011618 |)(?:\d{8}|(\d{4}\s\d{3}\s\d{3})|(\d{2}\s\d{4}\s\d{4}))$/;
        return re.test(phoneNumber);
    }

    const validateEstimatedLabourHours = (hours: string) => {
        const re = /^[0-9]*\.?[0-9]*$/;
        return re.test(hours);
    }

    const [callNrError, setCallNrError] = useState('');
    const [priorityError, setPriorityError] = useState('');
    const [areaError, setAreaError] = useState('');
    const [debtorIDError, setDebtorIDError] = useState('');
    const [contactNameError, setContactNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [equipmentIDError, setEquipmentIDError] = useState('');
    const [JobDescriptionError, setJobDescriptionError] = useState('');
    const [EstimatedLabourHoursError, setEstimatedLabourHoursError] = useState('');

    const openSharePoint = () => {
        const url = `${ESTIMATE_SHAREPOINT_PREFIX_URL}${store.ID}${ESTIMATE_SHAREPOINT_SUFFIX_URL}`;
        window.open(url, '_blank');
    }

    const handleOriginCallNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!validateOriginatingCallNumber(value)) {
            setCallNrError('Invalid Call number');
        } else {
            setCallNrError('');
        }
        store.requestDetails.setOriginCallNumber(value);
        store.requestDetails.setValidateCallError();
        store.requestDetails.setUseExistingCall(false);
    }

    const handleUseExistingCallChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            await store.requestDetails.validateCall(store.requestDetails.OriginCallNumber, store.requestDetails.EquipmentID, store.requestDetails.DebtorID, store.requestDetails.Location);
            if (store.requestDetails.ValidateCallError === null || store.requestDetails.ValidateCallError === '') {
                store.requestDetails.setUseExistingCall(true);
            } else {
                store.requestDetails.setUseExistingCall(false);
            }
        } else {
            store.requestDetails.setUseExistingCall(false);
        }
    };

    const debounceDebtorIDChange = useCallback(
        debounce((debtorID: string) => {
            store.requestDetails.validateDebtorID(debtorID);
        }, 1000),
        [store]
    );

    const handleDebtorIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        store.requestDetails.setDebtorID(value);
        debounceDebtorIDChange(value); // Call the debounced function
        store.requestDetails.setValidateCallError();
        store.requestDetails.setUseExistingCall(false);
    };

    const handleContactNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) {
            setContactNameError('Contact Name is required');
        } else {
            setContactNameError('');
        }
        store.requestDetails.setContactName(value);
    }

    const handleContactPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!validatePhoneNumber(value)) {
            setPhoneError('Invalid phone number');
        } else {
            setPhoneError('');
        }
        store.requestDetails.setContactPhone(value);
    }

    const validateEmail = async (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (re.test(String(email).toLowerCase())) {
            if (await store.emailValidate(email)) {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    }

    const debouncedHandleContactEmailChange = useCallback(
        debounce(async (ContactEmail: string) => {
            if (!(await validateEmail(ContactEmail))) {
                setEmailError('Invalid email address');
            } else {
                setEmailError('');
            }
            store.requestDetails.setContactEmail(ContactEmail);
        }, 1000),
        [store]
    );

    const handleContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        store.requestDetails.validateContactEmail(value);
        debouncedHandleContactEmailChange(value);
    }

    const handleAddressIDChange = (AddressID: string) => {
        const debtorID = store.requestDetails.DebtorID;
        store.requestDetails.setAddressID(debtorID, AddressID);
        store.requestDetails.setValidateCallError();
        store.requestDetails.setUseExistingCall(false);
        // Reset equipment ID
        store.requestDetails.setEquipmentID(debtorID, AddressID, '');
        // Trigger reload of equipment details
        store.requestDetails.validateEquipmentID(debtorID, AddressID, '');
    };

    const debounceEquipmentIDChange = useCallback(
        debounce((debtorID: string, locationID: string, equipmentID: string) => {
            store.requestDetails.validateEquipmentID(debtorID, locationID, equipmentID);
        }, 1000),
        [store]
    );

    const handleEquipmentIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const debtorID = store.requestDetails.DebtorID || '';
        const locationID = store.requestDetails.Location || '';
        const { value } = event.target;
        if (!value) {
            setEquipmentIDError('Equipment ID is required');
        } else {
            setEquipmentIDError('');
        }
        store.requestDetails.setEquipmentID(debtorID, locationID, value);
        debounceEquipmentIDChange(debtorID, locationID, value);
        store.requestDetails.setValidateCallError();
        store.requestDetails.setUseExistingCall(false);

    };

    const handleEngineHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        store.requestDetails.setEngineHours(value);
    }

    const handleJobDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) {
            setJobDescriptionError('Job description is required');
        } else {
            setJobDescriptionError('');
        }
        store.requestDetails.setJobDescription(value);
    }

    const handleEstimatedLabourHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!validateEstimatedLabourHours(value)) {
            setEstimatedLabourHoursError('Invalid or missing Estimated Labour Time');
        } else {
            setEstimatedLabourHoursError('');
        }
        store.requestDetails.setEstimatedLabourHours(value);
    }

    const validate = () => {
        let isValid = true;

        if (!store.requestDetails.Priority) {
            setPriorityError('Priority is required');
            isValid = false;
        } else {
            setPriorityError('');
        }

        if (!store.requestDetails.Area) {
            setAreaError('Area is required');
            isValid = false;
        } else {
            setAreaError('');
        }

        if (!validateOriginatingCallNumber(store.requestDetails.OriginCallNumber)) {
            setCallNrError('Invalid Call number');
            isValid = false;
        } else {
            setCallNrError('');
        }

        if (!store.requestDetails.CustomerName) {
            setDebtorIDError('Valid Debtor ID is required');
            isValid = false;
        } else {
            setDebtorIDError('');
        }

        if (!store.requestDetails.ContactName) {
            setContactNameError('Contact Name is required');
            isValid = false;
        } else {
            setContactNameError('');
        }

        if (!store.requestDetails.ContactPhone) {
            setPhoneError('Contact Phone Number is required');
            isValid = false;
        } else if (!validatePhoneNumber(store.requestDetails.ContactPhone)) {
            setPhoneError('Invalid phone number');
            isValid = false;
        } else {
            setPhoneError('');
        }

        if (!store.requestDetails.ContactEmail) {
            setEmailError('Contact Email Address is required');
            isValid = false;
        } else if (!validateEmail(store.requestDetails.ContactEmail)) {
            setEmailError('Invalid email address');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!store.requestDetails.Make) {
            setEquipmentIDError('Valid Equipment ID is required');
            isValid = false;
        } else {
            setEquipmentIDError('');
        }

        if (!store.requestDetails.JobDescription) {
            setJobDescriptionError('Job description is required');
            isValid = false;
        } else {
            setJobDescriptionError('');
        }

        if (!store.requestDetails.EstimatedLabourHours) {
            setEstimatedLabourHoursError('Estimated time is required');
            isValid = false;
        } else {
            setEstimatedLabourHoursError('');
        }

        if (!isValid) {
            alert('Please ensure all required fields are completed before proceeding.');
        }

        return isValid;
    }

    const handleSave = async () => {
        setEquipmentIDError('');
        let isValid = await validate();
        if (isValid) {
            setIsSaving(true);
            await store.save();
            setIsSaving(false);
        }
    };

    const handleSubmit = async () => {
        let isValid = await validate();
        if (!store.requestDetails.Make) {
            setEquipmentIDError('Valid EquipmentID is required');
            isValid = false;
        } else {
            setEquipmentIDError('');
        }
        if (isValid) {
            setIsSaving(true);
            await store.submit();
            setIsSaving(false);
        }
    };

    //const fileTypes = ['JPEG', 'PDF', 'etc.'];
    const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "BMP", "TIFF", "PDF", "DOC", "DOCX", "MSG", "XLS", "XLSX", "PPT", "PPTX", "TXT", "RTF", "CSV", "ODT", "ODS", "ODP", "SVG", "PSD", "EML"];

    const handleFileUploadChange = async (files: FileList) => {
        const uploadPromises = Array.from(files).map(async (file) => {
            const request = {
                RequestId: store.requestDetails.ID || "",
                EstimateNumber: store.requestDetails.EstimateNr || "",
                EquipmentID: store.requestDetails.EquipmentID || "",
                Make: store.requestDetails.Make || "",
                Model: store.requestDetails.Model || "",
                SerialNumber: store.requestDetails.SerialNumber || "",
                DebtorID: store.requestDetails.DebtorID || "",
                CustomerName: store.requestDetails.CustomerName || ""
            };
            await store.uploadFileToSharePoint(file, request);
        });

        await Promise.all(uploadPromises);
    };

    return (
        <form>
            <Accordion expanded={isRequestOpen}>
                <Grid container item xs={10}>
                    <Grid container direction="row" alignItems="center" item xs={3}>
                        <IconButton
                            onClick={handleExpandCollapseRequestClick}
                            aria-expanded={isRequestOpen}
                        >
                            {isRequestOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Request Details
                        </Typography>
                    </Grid>
                </Grid>
                <Paper>
                    <AccordionDetails>
                        <Grid container spacing={10}>
                            <Grid item lg={9} md={9} xs={9}>
                                < Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <SingleSelectWithKey
                                                label="Select Priority"
                                                values={store.prioritiesList}
                                                selectedValue={store.requestDetails.Priority}
                                                onChanged={store.selectPriority}
                                                error={!!priorityError}
                                                disabled={store.isReadOnly}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <SingleSelectWithKey
                                                label="Select Job Type"
                                                values={store.areaList}
                                                selectedValue={store.requestDetails.Area}
                                                onChanged={store.selectArea}
                                                error={!!areaError}
                                                disabled={store.isReadOnly}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Tooltip title={<Typography variant="body1">{!store.requestDetails.ID ? "Please save the request before proceeding" : "Click to view additional documents"}</Typography>}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={openSharePoint}
                                                        fullWidth
                                                        disabled={!store.requestDetails.ID}>
                                                        View Additional Documents
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                            <div style={{ marginTop: '20px' }}>
                                                <FileUploader
                                                    multiple={true}
                                                    handleChange={handleFileUploadChange}
                                                    name="file"
                                                    types={fileTypes}
                                                    disabled={!store.requestDetails.ID}
                                                    maxSize={100}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth={true}
                                            label="Originating Call Number"
                                            value={store.requestDetails.OriginCallNumber}
                                            error={!!callNrError}
                                            helperText={callNrError}
                                            onChange={handleOriginCallNumberChange}
                                            disabled={store.isReadOnly} />
                                    </Grid>                              
                                    <Grid item xs={2}>
                                        {store.requestDetails.OriginCallNumber && store.requestDetails.DebtorID
                                            && store.requestDetails.Location && store.requestDetails.Make &&(
                                            <div style={{ marginLeft: '50px', marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={store.requestDetails.UseExistingCall ?? false}
                                                    onChange={handleUseExistingCallChange}
                                                />
                                                <Typography variant="body1">Use Existing Call</Typography>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {store.requestDetails.ValidateCallError && (
                                        <TextField
                                            fullWidth={true}
                                            label=" "
                                            InputLabelProps={{ style: { color: 'transparent' } }}
                                            value={store.requestDetails.ValidateCallError}
                                            disabled
                                            variant="standard"
                                            InputProps={{ disableUnderline: true, style: { color: 'red', fontWeight: 'bold' } }}
                                        />
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Paper>
            </Accordion>

            <Accordion expanded={isCustomerDetailsOpen}>
                <Grid container item xs={9}>
                    <Grid container direction="row" alignItems="center" item xs={3}>
                        <IconButton
                            onClick={handleExpandCollapseCustomerDetailsClick}
                            aria-expanded={isCustomerDetailsOpen}
                        >
                            {isCustomerDetailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Customer Details
                        </Typography>
                    </Grid>
                </Grid>
                <Paper>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item lg={9} md={9} xs={9}>
                                < Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Debtor ID"
                                            margin="dense"
                                            value={store.requestDetails.DebtorID}
                                            onChange={handleDebtorIDChange}
                                            error={!!debtorIDError}
                                            helperText={debtorIDError}
                                            required
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Customer Name"
                                            margin="dense"
                                            value={store.requestDetails.CustomerName}
                                            disabled />
                                    </Grid>
                                </Grid>
                                < Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Contact Name"
                                            margin="dense"
                                            value={store.requestDetails.ContactName}
                                            onChange={handleContactNameChange}
                                            error={!!contactNameError}
                                            helperText={contactNameError}
                                            required
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Contact Phone Number"
                                            margin="dense"
                                            value={store.requestDetails.ContactPhone}
                                            onChange={handleContactPhoneChange}
                                            error={!!phoneError}
                                            helperText={phoneError}
                                            required
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Contact Email Address"
                                            margin="dense"
                                            value={store.requestDetails.ContactEmail}
                                            onChange={handleContactEmailChange}
                                            error={!!emailError}
                                            helperText={emailError}
                                            required
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <SingleSelect
                                                label="Select Location"
                                                values={store.requestDetails.AddressIDs}
                                                selectedValue={store.requestDetails.Location}
                                                onChanged={handleAddressIDChange}
                                                error={false}
                                                disabled={store.isReadOnly}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            label="Street Address"
                                            margin="dense"
                                            value={store.requestDetails.Address}
                                            disabled />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth={true}
                                                    label="Suburb"
                                                    margin="dense"
                                                    value={store.requestDetails.Suburb}
                                                    disabled />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth={true}
                                                    label="State"
                                                    margin="dense"
                                                    value={store.requestDetails.State}
                                                    disabled />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth={true}
                                                    label="Postcode"
                                                    margin="dense"
                                                    value={store.requestDetails.Postcode}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Paper>
            </Accordion>

            <Accordion expanded={isEquipmentDetailsOpen}>
                <Grid container item xs={10}>
                    <Grid container direction="row" alignItems="center" item xs={3}>
                        <IconButton
                            onClick={handleExpandCollapseEquipmentDetailsClick}
                            aria-expanded={isEquipmentDetailsOpen}
                        >
                            {isEquipmentDetailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Equipment Details
                        </Typography>
                    </Grid>
                </Grid>
                <Paper>
                    <AccordionDetails>
                        <Grid container spacing={10}>
                            <Grid item lg={9} md={9} xs={9}>
                                < Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth={true}
                                            label="Equipment ID"
                                            margin="dense"
                                            value={store.requestDetails.EquipmentID}
                                            onChange={handleEquipmentIDChange}
                                            error={!!equipmentIDError}
                                            helperText={equipmentIDError}
                                            required
                                            disabled={store.isReadOnly || store.requestDetails.CustomerName==='' || store.requestDetails.Address===''}/>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth={true}
                                                label="Job Type"
                                                margin="dense"
                                                value={store.requestDetails.FleetType}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    < Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <TextField
                                                fullWidth={true}
                                                label="Make"
                                                margin="dense"
                                                value={store.requestDetails.Make}
                                                disabled />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                fullWidth={true}
                                                label="Model"
                                                margin="dense"
                                                value={store.requestDetails.Model}
                                                disabled />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                < Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth={true}
                                            label="Serial Number"
                                            margin="dense"
                                            value={store.requestDetails.SerialNumber}
                                            disabled />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth={true}
                                            label="Fleet Number"
                                            margin="dense"
                                            value={store.requestDetails.FleetNumber}
                                            disabled />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth={true}
                                            label="Engine Hours"
                                            margin="dense"
                                            value={store.requestDetails.EngineHours}
                                            onChange={handleEngineHoursChange}
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Paper>
            </Accordion>

            <Accordion expanded={isJobDescriptionOpen}>
                <Grid container item xs={10}>
                    <Grid container direction="row" alignItems="center" item xs={3}>
                        <IconButton
                            onClick={handleExpandCollapseJobDescriptionClick}
                            aria-expanded={isJobDescriptionOpen}
                        >
                            {isJobDescriptionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Job Description
                        </Typography>
                    </Grid>
                </Grid>
                <Paper>
                    <AccordionDetails>
                        <Grid container spacing={10}>
                            <Grid item lg={9} md={9} xs={9}>
                                < Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Job Description"
                                            margin="dense"
                                            variant="outlined"
                                            value={store.requestDetails.JobDescription}
                                            onChange={handleJobDescriptionChange}
                                            multiline minRows={10}
                                            required
                                            error={!!JobDescriptionError}
                                            helperText={JobDescriptionError}
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                </Grid>
                                < Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth={true}
                                            label="Total Estimated Hours to Complete Work"
                                            margin="dense"
                                            value={store.requestDetails.EstimatedLabourHours}
                                            onChange={handleEstimatedLabourHoursChange}
                                            required
                                            error={!!EstimatedLabourHoursError}
                                            helperText={EstimatedLabourHoursError}
                                            disabled={store.isReadOnly} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Paper>
            </Accordion>



            <Grid container spacing={1}>
                <Grid item xs={12}></Grid>
                <Grid item lg={1} md={1} xs={1}>
                    <Button variant="contained" color="primary" onClick={handleSave} fullWidth disabled={store.isReadOnly || isSaving}>Save</Button>
                </Grid>
                <Grid item lg={1} md={1} xs={1}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth disabled={store.isReadOnly || isSaving}>Submit</Button>
                </Grid>
            </Grid>
        </form>
    );
});

// export default NewRequestForm;
export const NewRequestForm = inject(STORE_KEYS.RequestStore)(RequestFormComponent);

