import { inject, observer } from 'mobx-react';
import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useParams, NavLink } from 'react-router-dom';
import { PriorityConfig } from 'estimating-config/priority-config/PriorityConfig';
import { StatusConfig } from 'estimating-config/status-config/StatusConfig';
import { BranchConfig } from 'estimating-config/branch-config/BranchConfig';
import { AreaConfig } from 'estimating-config/area-config/AreaConfig';
import { EquipmentTypeConfig } from 'estimating-config/equipment-type-config/EquipmentTypeConfig';
import { RejectConfig } from './reject-config';
import { STORE_KEYS } from '@core';

enum SetupTabs {
    AreaConfig = 0,
    BranchConfig = 1,
    EquipmentTypeConfig = 2,
    PriorityConfig = 3,
    StatusConfig = 4,
    RejectConfig = 5
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            textTransform: 'capitalize'
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.primary.main
        },
    }));

interface PathParams {
    tab: string;
}

interface Props {
    AreaConfigStore: any; // replace 'any' with the actual type
    BranchConfigStore: any; // replace 'any' with the actual type
    EquipmentTypeConfigStore: any; // replace 'any' with the actual type
    PriorityConfigStore: any; // replace 'any' with the actual type
    StatusConfigStore: any; // replace 'any' with the actual type
    RejectConfigStore: any; // replace 'any' with the actual type
}

export const EstimatingConfig = inject(STORE_KEYS.AreaConfigStore, STORE_KEYS.BranchConfigStore, STORE_KEYS.EquipmentTypeConfigStore, STORE_KEYS.PriorityConfigStore, STORE_KEYS.StatusConfigStore, STORE_KEYS.RejectConfigStore)(observer((props: Props) => {
    const params = useParams<PathParams>();
    const classes = useStyles();

    let selectedTab: SetupTabs;
    switch (params.tab) {
        case 'AreaConfig':
            selectedTab = SetupTabs.AreaConfig;
            break;
        case 'BranchConfig':
            selectedTab = SetupTabs.BranchConfig;
            break;
        case 'EquipmentTypeConfig':
            selectedTab = SetupTabs.EquipmentTypeConfig;
            break;
        case 'PriorityConfig':
            selectedTab = SetupTabs.PriorityConfig;
            break;
        case 'StatusConfig':
            selectedTab = SetupTabs.StatusConfig;
            break;    
        default:
            selectedTab = SetupTabs.RejectConfig;
        
    }

    return (
        <React.Fragment>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
            >
                <NavLink to={params.tab ? `AreaConfig` : `estimatingConfig/AreaConfig`} color="primary" className={classes.link}>
                    <Tab label="Area Config" className={classes.label} />
                </NavLink>
                <NavLink to={params.tab ? `BranchConfig` : `estimatingConfig/BranchConfig`} color="primary" className={classes.link}>
                    <Tab label="Branch Config" className={classes.label} />
                </NavLink>
                <NavLink to={params.tab ? `EquipmentTypeConfig` : `estimatingConfig/EquipmentTypeConfig`} color="primary" className={classes.link}>
                    <Tab label="Equipment Type Config" className={classes.label} />
                </NavLink>
                <NavLink to={params.tab ? `PriorityConfig` : `estimatingConfig/PriorityConfig`} color="primary" className={classes.link}>
                    <Tab label="Priority Config" className={classes.label} />
                </NavLink>
                <NavLink to={params.tab ? `StatusConfig` : `estimatingConfig/StatusConfig`} color="primary" className={classes.link}>
                    <Tab label="Status Config" className={classes.label} />
                </NavLink>
                <NavLink to={params.tab ? `RejectConfig` : `estimatingConfig/RejectConfig`} color="primary" className={classes.link}>
                    <Tab label="Reject Config" className={classes.label} />
                </NavLink>
            </Tabs>


            {selectedTab === SetupTabs.AreaConfig && <AreaConfig AreaConfigStore={props[STORE_KEYS.AreaConfigStore]} />}
            {selectedTab === SetupTabs.BranchConfig && <BranchConfig BranchStore={props[STORE_KEYS.BranchConfigStore]} />}
            {selectedTab === SetupTabs.EquipmentTypeConfig && <EquipmentTypeConfig EquipmentTypeStore={props[STORE_KEYS.EquipmentTypeConfigStore]} />}
            {selectedTab === SetupTabs.PriorityConfig && <PriorityConfig PriorityStore={props[STORE_KEYS.PriorityConfigStore]} />}
            {selectedTab === SetupTabs.StatusConfig && <StatusConfig StatusStore={props[STORE_KEYS.StatusConfigStore]} />}
            {selectedTab === SetupTabs.RejectConfig && <RejectConfig RejectConfigStore={props[STORE_KEYS.RejectConfigStore]} />}
        </React.Fragment>
    );
}));


