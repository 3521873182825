import { types, Instance } from 'mobx-state-tree';
import { RejectReason } from './Reason';

import { uuidv4 } from '@utils';


export const RejectReasons = types.model({
  reasons: types.optional(types.array(RejectReason), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const reason: any = {
            id: uuidv4()
        };
        
        self.reasons.push(reason);
      }
    };
  })
  .views((self) => ({
    equals(reasons) {
        return self.reasons.filter(
          (t) => !reasons.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type RejectReasonsModel = Instance<typeof RejectReasons>;
