import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IRejectStore } from './store/RejectConfigStore';
import { RejectReasons } from './Reasons';
import { STORE_KEYS } from '@core';


interface Props {
  RejectConfigStore: IRejectStore;
}

const RejectComponent: React.FC<Props> = observer((props) => {
  const store = props.RejectConfigStore;
  const { reasons, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <RejectReasons RejectReasonModel={reasons} 
    reasons={reasons.reasons} 
    hasChanges={hasChanges(reasons.reasons)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const RejectConfig = inject(STORE_KEYS.RejectConfigStore)(RejectComponent);
