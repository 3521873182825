import { observer } from 'mobx-react';
import React, {useState} from 'react';
import { TextField, Button, makeStyles, createStyles, Theme, Checkbox, Tooltip, Typography } from '@material-ui/core';
import { RejectReasonModel } from './domain/Reason';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
    reason: RejectReasonModel
    onDeleteClick?: (area: RejectReasonModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reasonsWrapper: {
        display: 'flex',
        gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
}));

export const RejectReason: React.FC<Props> = observer((props) => {

const { reason, onDeleteClick } = props;
const classes = useStyles();
const [confirmationOpen, setConfirmationOpen] = useState(false);


const handleDeleteClick = () => {
  setConfirmationOpen(true);
};

const handleConfirmationClose = (confirmed: boolean) => {
  setConfirmationOpen(false);
  if (onDeleteClick && confirmed) {
      onDeleteClick(reason);
  }
};

const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  reason.setReason(event.target.value);
};

const handleHiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  reason.setHidden(event.target.checked);
};

  return (
        <div className={classes.reasonsWrapper}>
          <TextField
            fullWidth={true}
            label="Reason"
            margin="dense"
            variant="outlined"
            value = {reason.reason}
            onChange={handleTextChange}
          />
          <Tooltip title={<Typography variant="body1">Reject Reason hidden on Estimate page</Typography>}>
          <Checkbox
           color='primary'
            checked={reason.hidden}
            onChange={handleHiddenChange}
          />
          </Tooltip>
          <Button
            color="primary"
            className={classes.label}
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
          <ConfirmationDialog
              open={confirmationOpen}
              onClose={handleConfirmationClose}
              declineButtonTitle="No"
              confirmButtonTitle="Yes"
              title="Are you sure you want to delete this item?"
          />
        </div>
  );
});