import { Instance, types, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { EstimateDetails } from '../domain/EstimateDetails';

// Add this helper function at the top of your file
const trimStringFields = (obj: any) => {
  for (let prop in obj) {
    if (typeof obj[prop] === 'string') {
      obj[prop] = obj[prop].trim();
    }
  }
  return obj;
};

export const EstimateDetailsStore = types
  .model({
    ID: types.optional(types.string, ''),
    uploadError: types.optional(types.string, ''),
    areaList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    equipmentTypeList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    estimateStatusList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    rejectReasonList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    isLoading: types.optional(types.boolean, false),
    estimateDetails: types.optional(EstimateDetails, {}),
    estimatesList: types.optional(types.array(EstimateDetails), []),
    statusName: types.optional(types.string, ''),
  })
  .actions((self) => {
    const { api, } = getEnv<IStoresEnv>(self);

    const fetchData = flow(function* fetch() {
      self.isLoading = true;

      const areaResponse = yield api.get(`/api/EstimateAreaConfig`);
      const areaTypes = areaResponse.data.areas || [];
      self.areaList = areaTypes.map((x) => ({ id: x.id, value: x.area })) || [];

      const equipmentTypeResponse = yield api.get(`/api/EstimateEquipmentTypeConfig`);
      const equipmentTypes = equipmentTypeResponse.data.equipmentTypes || [];
      self.equipmentTypeList = equipmentTypes.map((x) => ({ id: x.id, value: x.equipmentType })) || [];

      const estimateStatusResponse = yield api.get(`/api/EstimateStatusConfig/Visible`);
      const estimateStatusTypes = estimateStatusResponse.data.statuses || [];
      self.estimateStatusList = estimateStatusTypes.map((x) => ({ id: x.id, value: x.status })) || [];

      const rejectReasonResponse = yield api.get(`/api/EstimateRejectConfig`);
      const reasons = rejectReasonResponse.data.reasons || [];
      self.rejectReasonList = reasons.map((x) => ({ id: x.id, value: x.reason })) || [];

      self.isLoading = false;
    });

    const getStatus = flow(function* getStatus(statusID: string) {        
      const response = yield api.get(`/api/EstimateStatusConfig/id?statusID=${statusID}`);
      self.statusName = response.data.status;
    }); 

    const getStatusID = flow(function* getStatusID(statusName: string) {
    //const getStatusID = (statusName: string) => {
      //const status = self.estimateStatusList.find(s => s.value === statusName);
      const status = yield api.get(`/api/EstimateStatusConfig/name?statusName=${statusName}`);
      self.statusName = statusName;
      self.estimateDetails.Status = status.data.id;
    });


    const selectEstimateStatus = flow(function* selectEstimateStatus(status: string) {
      self.estimateDetails.Status = status;
      yield getStatus(status); // update statusName whenever the status changes
    });

    const selectEstimateNumber = flow(function* selectEstimateNumber(requestNumber: string) {
      self.ID = requestNumber;
      self.estimateDetails.Location = '';

      const requestResponse = yield api.get(`/api/Estimates/id?estimateNumber=${self.ID}`);

      // Trim the response data
      const trimmedData = trimStringFields(requestResponse.data);

      // Then use 'trimmedData' instead of 'requestResponse.data'
      self.estimateDetails.ID = trimmedData.id || '';
      self.estimateDetails.EstimateID = trimmedData.estimateID || 0;
      self.estimateDetails.CreatedAt = trimmedData.createdAt || '';
      self.estimateDetails.RequestID = trimmedData.requestID || '';
      self.estimateDetails.Priority = trimmedData.priority || '';
      self.estimateDetails.FleetType = trimmedData.fleetType || '';
      self.estimateDetails.Address = trimmedData.address || '';
      self.estimateDetails.Branch = trimmedData.branch || '';
      self.estimateDetails.ContactEmail = trimmedData.contactEmail || '';
      self.estimateDetails.ContactName = trimmedData.contactName || '';
      self.estimateDetails.ContactPhone = trimmedData.contactPhone || '';
      self.estimateDetails.CustomerName = trimmedData.customerName|| '';
      self.estimateDetails.DebtorID = trimmedData.debtorID || '';
      self.estimateDetails.EngineHours = trimmedData.engineHours || '';
      self.estimateDetails.EquipmentID = trimmedData.equipmentID || '';
      self.estimateDetails.TimeEst = trimmedData.timeEst || '';
      self.estimateDetails.FleetNumber = trimmedData.fleetNumber || '';
      self.estimateDetails.JobDescription = trimmedData.jobDescription || '';
      self.estimateDetails.Location = trimmedData.location || '';
      self.estimateDetails.Suburb = trimmedData.suburb || '';
      self.estimateDetails.Postcode = trimmedData.postcode || '';
      self.estimateDetails.State = trimmedData.state || '';
      self.estimateDetails.Make = trimmedData.make || '';
      self.estimateDetails.Model = trimmedData.model || '';
      self.estimateDetails.OriginCallNr = trimmedData.originCallNr || '';
      self.estimateDetails.UseExistingCall = trimmedData.useExistingCall || false;
      self.estimateDetails.Owner = trimmedData.owner || '';
      self.estimateDetails.SerialNumber = trimmedData.serialNumber || '';
      self.estimateDetails.Status = trimmedData.status || '';
      self.estimateDetails.EquipmentType = trimmedData.equipmentType || '';
      self.estimateDetails.Area = trimmedData.area || '';
      self.estimateDetails.SpecialNotes = trimmedData.specialNotes || '';
      self.estimateDetails.QuotedFor = trimmedData.quotedFor || '';
      self.estimateDetails.TransportCost = trimmedData.transportCost || 0;
      self.estimateDetails.TransportSell = trimmedData.transportSell || 0;
      self.estimateDetails.Freight  = trimmedData.freight || 0;
      self.estimateDetails.Enviro = trimmedData.enviro || 0;
      self.estimateDetails.Consumables = trimmedData.consumables || 0;
      self.estimateDetails.OtherCost = trimmedData.otherCost || 0;
      self.estimateDetails.AdminFee = trimmedData.adminFee || 0;
      self.estimateDetails.PriceLevel = trimmedData.priceLevel || '';
      self.estimateDetails.LabourRate = trimmedData.labourRate || 0;
      self.estimateDetails.LabourCost = trimmedData.labourCost || 0;
      self.estimateDetails.EstimateNr = trimmedData.estimateNr || '';
      self.estimateDetails.Revision = trimmedData.revision || 0;
      self.estimateDetails.Comments = trimmedData.comments || '';
      self.estimateDetails.RejectReason = trimmedData.rejectReason || null;
      self.estimateDetails.CallNr = trimmedData.callNr || null;
      self.estimateDetails.ApprovalPO = trimmedData.approvalPO || null;

      // Call 'fetchData' with 'yield'
      yield fetchData();
      yield selectEstimateStatus(self.estimateDetails.Status);
    });

    const save = flow(function* save() {
      self.isLoading = true;
      const response = yield api.post(`/api/Estimates/save`,
          { payload: self.estimateDetails });
      self.isLoading = false;
      return response;
    });

    const acceptEstimate = flow(function* acceptEstimate() {
      self.isLoading = true;
      const response = yield api.post(`/api/Estimates/acceptEstimate`,
          { payload: self.estimateDetails });
      self.isLoading = false;
      return response;
    });

    const rejectEstimate = flow(function* rejectEstimate() {
      self.isLoading = true;
      const response = yield api.post(`/api/Estimates/rejectEstimate`,
          { payload: self.estimateDetails });
      self.isLoading = false;
      return response;
    });

    const createRevision = flow(function* createRevision() {
        self.isLoading = true;
        
        const response = yield api.post(`/api/Estimates/createRevision?estimateID=${self.ID}`,
            { payload: self.estimateDetails.ID });
        yield getStatusID("New");
        self.ID=response.data.id;
        self.estimateDetails.ID=response.data.id;
        self.estimateDetails.Revision=response.data.revision;
        self.isLoading = false;
      });

    const emailValidate = flow(function* emailValidate(email: string) {
      self.isLoading = true;
      const response = yield api.get(`/api/EstimateEmailValidate/validateEmail?email=${email}`);
      self.isLoading = false;
      return response.data;
    });

       const uploadFileToSharePoint = flow(function* uploadFileToSharePoint(file: File, request) {
      self.isLoading = true;

       const formData = new FormData();
   
      formData.append('file', file);
      formData.append('RequestId', request.RequestId);
      formData.append('EstimateNumber', request.EstimateNumber);
      formData.append('EquipmentID', request.EquipmentID);
      formData.append('Make', request.Make);
      formData.append('Model', request.Model);
      formData.append('SerialNumber', request.SerialNumber);
      formData.append('DebtorID', request.DebtorID);
      formData.append('CustomerName', request.CustomerName);

      try {
        const response = yield api.postFile('/api/sharepoint/upload', { payload: formData });
        if (response.status === 200) {
          console.log('File uploaded successfully');
        } else {
          console.error('Failed to upload file:', response.error);
        }
      } catch (error) {
        self.uploadError = error.message; 
        console.error('Error uploading files:', error);
      } finally {
        self.isLoading = false;
      }
    });
    const clearUploadError = () => {
      self.uploadError = '';
    };


    return {
      selectEstimateNumber,
      selectArea(area: string) {
        self.estimateDetails.Area = area;
      },
      selectEquipmentType(equipmentType: string) {
        self.estimateDetails.EquipmentType = equipmentType;
      },
      selectRejectReason(rejectReason: string) {
        self.estimateDetails.RejectReason = rejectReason;
      },
      
      save,
      acceptEstimate,
      rejectEstimate,
      createRevision,
      fetchData,
      getStatusID,
      getStatus,
      selectEstimateStatus,
      emailValidate,
      uploadFileToSharePoint,
      clearUploadError,
    };
  })
  .views((self) => {
    return {
      get isReadOnly() {
        return (self.statusName === 'Cancelled' || self.statusName === 'Estimate Sent' || self.statusName === 'Reminder Sent' ||
          self.statusName === 'Lost' || self.statusName === 'Estimate Accepted' || self.statusName === 'Invoiced'); // use statusName in the view
      },

      get uploadErrorMessage() {
        return self.uploadError; // This view returns the upload error message
      },

      get isEstimateReady() {
        return (self.statusName === 'Estimate Ready'); // use statusName in the view
      },

      get isEstimateSent() {
        return ( self.statusName === 'Estimate Sent' || self.statusName === 'Reminder Sent' ); // use statusName in the view
      },

      get isEstimateEditBlocked() {
        return (self.statusName === 'Lost' || self.statusName === 'Estimate Ready' ||self.statusName === 'Estimate Sent' || self.statusName === 'Reminder Sent');
      }
    };
  });

export type IEstimateDetailsStore = Instance<typeof EstimateDetailsStore>;